import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import { Grid } from "semantic-ui-react";
import {
  PageLayout,
  HeroElement,
  MenuFilesDisplay,
} from "@fishrmn/fishrmn-components";
import { getPageHeroImages } from "../utils/images";

import Layout from "../components/Layout";

export default class extends React.PureComponent {
  render() {
    const data = this.props.data.allFisherman.edges[0].node;
    const pageData = this.props.data.fishermanWebsitePage || {};
    const pageComponents = (pageData && pageData.components) || [];
    const heroConfigurations = pageComponents.filter(
      ({ fastId }) => fastId === "HeroElement"
    );
    const hasHeroImage =
      heroConfigurations.length > 0 && heroConfigurations[0].files.length > 0;

    return (
      <Layout>
        <Helmet>
          <title>{data.businessName} | Menu</title>
          <meta name="description" content={pageData.description} />
        </Helmet>
        <PageLayout
          hero={
            hasHeroImage ? (
              <HeroElement
                header={"Menu"}
                tagline={""}
                images={[...getPageHeroImages(pageComponents)]}
                showMap={false}
                ctas={[]}
                ctaInverted={true}
                ctaColor={"white"}
                containerColor={"primary"}
                textColor={"white"}
                showMultiple={true}
                overlay={true}
                fullWidth={true}
                parallax={false}
                containerAs={"none"}
                height={"100vh"}
                gutter={false}
              />
            ) : (
              false
            )
          }
          subfooter={false}
        >
          <Grid
            stackable
            verticalAlign={"top"}
            textAlign={"left"}
            className="menu-page"
          >
            <Grid.Column width={16} style={{ padding: "5rem" }}>
              {/* <MenuFilesDisplay files={data.menuImages} /> */}
            </Grid.Column>
          </Grid>
        </PageLayout>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          businessName
        }
      }
    }
    fishermanWebsitePage(title: { eq: "Menu" }) {
      description
      components {
        fastId
        order
        files {
          file
        }
      }
    }
  }
`;
